import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/maximize-space-san-diego-homes");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How to Maximize Space in San Diego’s Smaller Homes.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Are the properties you’re viewing in San Diego big on charm but small on space? That
                can often be the case in highly desirable areas, as there simply isn’t enough room
                to accommodate the building of large-scale properties. But don’t let a lack of space
                stop you from enjoying a cozy condo or smaller home.
              </CommonParagraph>
              <CommonParagraph>
                Here’s how to maximize home space, opening up more options for you to consider as
                you shop for the perfect place.
              </CommonParagraph>
              <Heading type="h2">Make Smart Furniture Choices</Heading>
              <CommonParagraph>
                Choosing multi-functional furniture is one of the easiest ways to create more room
                in a smaller home. When the time comes, look for pieces that serve multiple
                purposes. For example, a sofa bed could eliminate the need for a dedicated guest
                bedroom, and a fold-down table offers a convenient workspace that can be easily
                stowed when not in use.
              </CommonParagraph>
              <CommonParagraph>
                Even smaller items can help keep an area organized and more roomy, like an ottoman
                with built-in storage.
              </CommonParagraph>
              <Heading type="h2">Leverage Vertical Space</Heading>
              <CommonParagraph>
                Floor space limited? Turn your attention to vertical possibilities. Think beyond
                traditional shelving and get creative with how you keep clutter off the floor.
                Install cabinets and consider using a tall bookcase as vertical storage.
              </CommonParagraph>
              <CommonParagraph>
                Does your{" "}
                <BlogLink url="https://selbysellssd.com/buy-house-san-diego" text="new home" /> have
                a small kitchen? Put hooks to use! By utilizing hooks to hang pans, pots, and even
                utensils on the walls, you can save counter space. Simple moves like this can make
                the home feel less cramped and more organized.
              </CommonParagraph>
              <Heading type="h2">Use Light & Color to Your Advantage</Heading>
              <CommonParagraph>
                The right lighting and color palette can make any space feel larger. To create an
                open, airy feel, use colors that reflect natural light, like:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>Whites</li>
                <li>Soft grays</li>
                <li>Pastels</li>
              </ul>
              <CommonParagraph>
                While many consider darker colors stylish, they can make rooms feel more enclosed
                and, thus, even smaller.
              </CommonParagraph>
              <CommonParagraph>
                What’s a small home’s best friend? Natural light. Wherever possible, maximize the
                light entering a space by utilizing mirrors, glass doors, or large windows. Mirrors
                help create the illusion of more space, while sheer curtains allow sunlight to
                filter in while maintaining your privacy.
              </CommonParagraph>
              <Heading type="h2">Visualize Open Layout Possibilities</Heading>
              <CommonParagraph>
                Don’t overlook a property because it appears to be too small for your needs; are
                there updates you can make that can turn a small home into your dream one? As you
                explore{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/"
                  text="different neighborhoods"
                />{" "}
                and your options, be on the lookout for unnecessary walls you can knock down to open
                up a space and create a more inviting flow between rooms.
              </CommonParagraph>
              <CommonParagraph>
                Don’t limit your thinking to layout! Think about how each room can serve multiple
                purposes. Can the living room also function as a dining area with help from
                multipurpose furniture? Using each space in many different ways is an easy way to
                make a smaller home more versatile.
              </CommonParagraph>
              <Heading type="h2">Put Outdoor Areas to Use</Heading>
              <CommonParagraph>
                San Diego is known for its gorgeous year-round weather, which can help you extend
                your living space into outdoor areas. Transform a balcony or backyard into a
                lounging space with the help of a few chairs, or place a foldable table on a patio
                to turn it into a dining area during the summer months.
              </CommonParagraph>
              <CommonParagraph>
                Maximize the space of outdoor areas by designating separate zones for activities
                like gardening and lounging. Doing so can give the illusion of a larger space, and
                with help from an outdoor rug and some string lights, you can easily create a
                welcoming extension of your home.
              </CommonParagraph>
              <Heading type="h2">Create Your Dream Home With Help From The Selby Team</Heading>
              <CommonParagraph>
                Our friendly Southern California realtors are here to help you find the perfect home
                for you, whether that means a house that’s perfect as-is or one with plenty of
                space-saving potential. Start your search for San Diego small homes today–
                <ContactSlideoutLink text="reach out" /> to The Selby Team!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
